import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { FC, memo, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import LoadingComponent from 'src/components/LoadingComponent';
import { fiveZeroZeroErrorMessage } from 'src/constants/messages';
import LicenseContext from 'src/contexts/LicenseContext';
import datetimeDecorator from 'src/decorators/datetime.decorator';
import numberDecorator from 'src/decorators/number.decorator';
import { CalenderResourceEntity } from 'src/entities/CalenderResource.entity';
import { DeliveryEventEntity } from 'src/entities/DeliveryEvent.entity';
import { OperationEventEntity } from 'src/entities/OperationEvent.entity';
import { OrderEntity } from 'src/entities/orderEntity';
import { TimelineEditBaseOperationEntity, TimelineEditDeliveryEntity } from 'src/entities/planningEntity';
import { ManualPlanningResponseEntity } from 'src/entities/planningResponseEntity';
import { BaseOperationEntityWithPlaceType, PlanningsDeliveryEntity } from 'src/entities/PlanningsDelivery.entity';
import { PlanningsDriverEntity } from 'src/entities/PlanningsDriver.entity';
import { PlanningsGroupEntity } from 'src/entities/PlanningsGroup.entity';
import { PlanningsOperationEntity } from 'src/entities/PlanningsOperation.entity';
import { PlanningsTruckEntity } from 'src/entities/PlanningsTruck.entity';
import { useQueryGarages } from 'src/hooks/useQueryGarages';
import { useInfiniteQueryOrders, useMutationOrder } from 'src/hooks/useQueryOrders';
import { useMutationPlanning } from 'src/hooks/useQueryPlanning';
import { useQueryPlanningGroups } from 'src/hooks/useQueryPlanningGroups';
import { useQueryPlanningOrderStatistics } from 'src/hooks/useQueryPlanningOrderStatistics';
import { useQueryPlanningsDeliveries } from 'src/hooks/useQueryPlanningsDeliveries';
import { useQueryPlanningsDrivers } from 'src/hooks/useQueryPlanningsDrivers';
import { useQueryPlanningsTrucks } from 'src/hooks/useQueryPlanningsTrucks';
import { datetimeUtil } from 'src/utils/datetime.util';

import { useMutationPlanningOperations } from '../../hooks/useQueryPlanningsOperations';
import { deliveryEntitiesReducer, driverEntitiesReducer, orderEntityMapReducer, selectedOrderIdsReducer } from '../V2Plans/reducers';

import Presenter from './Presenter';
import { groupEntitiesReducer, orderEntitiesReducer, selectedGroupEntityReducer } from './Reducer';

type Props = {
  startDate: Date;
  endDate: Date;
  unit: string;
  customInputFields: string[];
}

const Component: FC<Props> = memo(({
  startDate,
  endDate,
  unit,
  customInputFields,
}) => {
  const startYMD = datetimeDecorator.toYyyyMmDd(startDate);
  const endYMD = datetimeDecorator.toYyyyMmDd(endDate);

  const context = useContext(LicenseContext);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const resetQueries = useCallback(() => {
    // eslint-disable-next-line no-void
    void Promise.all([
      queryClient.resetQueries(['useQueryPlanningsOperations']),
      queryClient.resetQueries(['useQueryPlanningsDeliveries', { startOn: startYMD, endOn: endYMD }])
    ]);
  }, [queryClient, startYMD, endYMD]);

  const [selectedOrderIds, dispatchSelectedOrderIds] = useReducer(selectedOrderIdsReducer, []);

  const { data: garageData, isLoading: garageDataIsLoading } = useQueryGarages(0, { enabled: true });
  const { data: truckData, isLoading: trucksIsLoading } = useQueryPlanningsTrucks(startYMD, endYMD);
  const { data: driverData, isLoading: driversIsLoading } = useQueryPlanningsDrivers(startYMD, endYMD);
  const { data: deliveryData, isLoading: deliveriesIsLoading } = useQueryPlanningsDeliveries(startYMD, endYMD);
  const { data: orderData, isLoading: ordersIsLoading } = useInfiniteQueryOrders(startDate, endDate);
  const { data: planningGroupsData } = useQueryPlanningGroups();
  const { data: planningOrderStatisticsEntity } = useQueryPlanningOrderStatistics(startYMD, endYMD, selectedOrderIds);

  const { manualPlanning } = useMutationPlanning();
  const { operationBulkResetting } = useMutationOrder();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groupEntities, dispatchGroupEntities] = useReducer(groupEntitiesReducer, []);
  const [orderEntities, dispatchOrderEntities] = useReducer(orderEntitiesReducer, []);
  const [selectedGroupEntity, dispatchSelectedGroupEntity] = useReducer(selectedGroupEntityReducer, undefined);
  const [driverEntities, dispatchDriverEntities] = useReducer(driverEntitiesReducer, []);
  const [deliveryEntities, dispatchDeliveryEntities] = useReducer(deliveryEntitiesReducer, []);
  const [orderEntityMap, dispatchOrderEntityMap] = useReducer(orderEntityMapReducer, null);
  const [orderSearchKw, setOrderSearchKw] = useState<string>('');
  const [currentlyOrderSearching, setCurrentlyOrderSearching] = useState<boolean>(false);

  const reset = useCallback(() => {
    resetQueries();
  }, [resetQueries]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const mutateDeleteOrdersOperations: (requestOrderIds: number[]) => void = async (requestOrderIds) => {
    enqueueSnackbar('配送計画を削除します');

    try {
      setIsLoading(true);
      await operationBulkResetting.mutateAsync(requestOrderIds);

      resetQueries();

      enqueueSnackbar('配送計画を削除しました');
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error as unknown as AxiosError<string>;
        enqueueSnackbar(axiosError.response.data);
      }
      reset();
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const calculateSummaries = useCallback((entity: PlanningsDeliveryEntity, truck: PlanningsTruckEntity) => {
    const unloadCount = entity.operations.reduce((sum, it) => {
      if (it.action === '積') return sum;

      const position = [it.latitude, it.longitude].join('-');
      return sum.add(position);
    }, new Set()).size;

    const { maxWeight } = entity.operations.reduce((sum, it) => {
      sum.currentWeight += (it.itemTotalWeightForCalculation * (it.action === '積' ? 1 : -1));
      sum.maxWeight = Math.max(sum.maxWeight, sum.currentWeight);
      return sum;
    }, { currentWeight: 0, maxWeight: 0 });
    const weightRatio = numberDecorator.toRoundedRate(maxWeight / truck.maximumLoadingCapacityWeightForCalculation, '') || '- %';

    const { maxVolume } = entity.operations.reduce((sum, it) => {
      sum.currentVolume += (it.itemTotalVolumeMm3 * (it.action === '積' ? 1 : -1));
      sum.maxVolume = Math.max(sum.maxVolume, sum.currentVolume);
      return sum;
    }, { currentVolume: 0, maxVolume: 0 });
    const volumeRatio = numberDecorator.toRoundedRate(maxVolume / truck.loadingPlatformVolumeMm3, '') || '- %';

    let workingRatio = '- %';
    if (entity.operations.length > 0 && entity.departureOperation && entity.arrivalOperation) {
      const wMinutes = datetimeUtil.diffMinutes(new Date(entity.startAt), new Date(entity.endAt));
      const pMinutes = datetimeUtil.diffMinutes(
        new Date(entity.departureOperation.departure_at),
        new Date(entity.arrivalOperation.arrival_at)
      );
      workingRatio = numberDecorator.toRoundedRate(pMinutes / wMinutes, '');
    }

    return [
      ['納品先数:', unloadCount, '件'].join(''),
      ['積載率(重量, 体積):', [weightRatio, volumeRatio].join(',')].join(' '),
      ['稼働率:', workingRatio].join('')
    ].join(', ');
  }, []);

  const [deliveryEvents, setDeliveryEvents] = useState<DeliveryEventEntity[]>([]);
  const [resources, setResources] = useState<CalenderResourceEntity[]>([]);

  useEffect(() => {
    if (deliveriesIsLoading) {
      setDeliveryEvents([]);
      return;
    }

    setDeliveryEvents(
      deliveryData.map((datum) => {
        const id = [
          'delivery',
          datum.id
        ].join('-');
        // const title = '';
        const title = [
          datetimeDecorator.toDayAndHourMinutes(new Date(datum.startAt)),
          datetimeDecorator.toDayAndHourMinutes(new Date(datum.endAt))
        ].join(' ～ ');
        const start = new Date(datum.startAt).toISOString();
        const end = new Date(datum.endAt).toISOString();
        const resourceId = `${datum.id}`;
        const color = '#eee'; // theme.colors.secondary.main;

        return {
          id,
          title,
          start,
          end,
          resourceId,
          backgroundColor: color,
          borderColor: color,
          display: 'background',
          editable: false
        };
      })
    );
  }, [deliveriesIsLoading, deliveryData]);

  useEffect(() => {
    if (deliveriesIsLoading || garageDataIsLoading || trucksIsLoading || !driverData) {
      setResources([]);
      return;
    }

    const deliveryTruckIds = deliveryData.map((it) => it.truckId);
    const filteredTruckData: PlanningsTruckEntity[] = (selectedGroupEntity
      ? truckData.filter((truck) => selectedGroupEntity.truckIds.includes(truck.id)) : truckData)
      .filter((truck) => deliveryTruckIds.includes(truck.id));
    const garageNameMap = new Map<number, string>();
    garageData.forEach((garage) => {
      garageNameMap.set(garage.id, garage.name);
    });
    const truckGarageMap = new Map<number, string>();
    filteredTruckData.forEach((truck) => {
      truckGarageMap.set(truck.id, garageNameMap.get(truck.garageId) || '');
    });

    const res = filteredTruckData.map((truck, tIndex) => (
      deliveryData.filter((delivery) => delivery.truckId === truck.id).map((datum, dIndex) => {
        const driver: PlanningsDriverEntity = driverData.find((it) => it.id === datum.driverId);

        const startDateObj = new Date(datum.startAt);
        const endDateObj = new Date(datum.endAt);
        const startHM = datetimeDecorator.toHourMinutes(startDateObj);
        let endHM = datetimeDecorator.toHourMinutes(endDateObj);
        if (startDateObj.getDate() !== endDateObj.getDate()) {
          const hours = new Date(datum.endAt).getHours() + 24;
          endHM = `${hours}:${endDateObj.getMinutes()}`;
        }

        const weightAndVolume = [
          numberDecorator.convertGramToKg(
            truck.maximumLoadingCapacityWeightForCalculation
          ),
          numberDecorator.convertMm3ToM3(
            truck.loadingPlatformVolumeMm3
          )
        ].filter((maybe) => maybe);

        const time = [startHM, endHM].join(' ～ ');

        const title = [
          truck.licensePlateValue,
          weightAndVolume.map((it) => `(${it})`),
          driver.name,
          time,
        ].join(' ');

        const workingTime = [datetimeDecorator.toDayAndHourMinutes(startDateObj), datetimeDecorator.toDayAndHourMinutes(endDateObj)].join(' ～ ');
        const truckName = [truck.licensePlateValue, weightAndVolume.map((it) => `(${it})`)].join(' ');
        const driverName = [workingTime, driver.name].join(' ');
        const summaries = calculateSummaries(datum, truck);
        const garageName = truckGarageMap.get(truck.id) || '';

        return {
          id: `${datum.id}`,
          title,
          driverEntity: driver,
          truckEntity: truck,
          truckName,
          driverName,
          garageName,
          summaries,
          workingTime,
          resourceIndex: tIndex * 1000 + dIndex
        };
      })
    )).flat();

    setResources(res);
  }, [calculateSummaries, deliveriesIsLoading, deliveryData, driverData, truckData, selectedGroupEntity, garageData, planningGroupsData, garageDataIsLoading, trucksIsLoading]);

  const updateSelectedGroupEntity = useCallback((entity: PlanningsGroupEntity | undefined) => {
    dispatchSelectedGroupEntity({
      type: 'set',
      payload: entity
    });
  }, []);

  useMemo(() => {
    dispatchGroupEntities({
      type: 'set',
      payload: planningGroupsData
    });
  }, [planningGroupsData]);

  const calculateStayingMinutes = useCallback((order: OrderEntity) => {
    const { config } = context;

    if (!config) {
      return { loadingDurationSeconds: 600, unloadingDurationSeconds: 600 };
    }

    if (config.loading_unloading_duration_table) {
      let weightLoadingDuration = 0;
      let volumeLoadingDuration = 0;
      let weightUnloadingDuration = 0;
      let volumeUnloadingDuration = 0;
      config.loading_unloading_duration_table.forEach((duration) => {
        if (order.item_total_weight_kg <= duration.weightKg) {
          weightLoadingDuration = duration.loadingDurationSeconds;
          weightUnloadingDuration = duration.unloadingDurationSeconds;
        }
        if (order.item_total_volume_m3 && order.item_total_volume_m3 <= duration.volumeM3) {
          volumeLoadingDuration = duration.loadingDurationSeconds;
          volumeUnloadingDuration = duration.unloadingDurationSeconds;
        }
      });
      const latestDuration = config.loading_unloading_duration_table.slice(-1)[0];
      if (weightLoadingDuration === 0 && weightUnloadingDuration === 0) {
        weightLoadingDuration = latestDuration.loadingDurationSeconds;
        weightUnloadingDuration = latestDuration.unloadingDurationSeconds;
      }
      if (volumeLoadingDuration === 0 && volumeUnloadingDuration === 0) {
        volumeLoadingDuration = latestDuration.loadingDurationSeconds;
        volumeUnloadingDuration = latestDuration.unloadingDurationSeconds;
      }
      return {
        loadingDurationSeconds: Math.max(weightLoadingDuration, volumeLoadingDuration),
        unloadingDurationSeconds: Math.max(weightUnloadingDuration, volumeUnloadingDuration)
      };
    }
    // 積み降ろし時間のデフォルト値 https://github.com/logposeTech/planning_api/blob/00a918e709d20647646fc973f016a3c01492660d/app/models/company.rb#L299-L300
    return {
      loadingDurationSeconds: config.loading_duration_seconds || 240,
      unloadingDurationSeconds: config.unloading_duration_seconds || 600
    };
  }, [context]);

  useEffect(() => {
    if (!orderData) return;

    const entities = orderData.pages.flatMap((it) => it.data).map((order) => {
      if (!context.config) return order;

      if (!order.loading_staying_minutes || !order.unloading_staying_minutes) {
        const { loadingDurationSeconds, unloadingDurationSeconds } = calculateStayingMinutes(order);
        order.loading_staying_seconds ||= loadingDurationSeconds;
        order.loading_staying_minutes ||= loadingDurationSeconds / 60;
        order.unloading_staying_seconds ||= unloadingDurationSeconds;
        order.unloading_staying_minutes ||= unloadingDurationSeconds / 60;
      }
      return order;
    });
    dispatchOrderEntities({
      type: 'set',
      payload: entities
    });
  }, [calculateStayingMinutes, context.config, orderData]);

  const save = useCallback((
    operationEntities: PlanningsOperationEntity[],
    baseOperationEntities: BaseOperationEntityWithPlaceType[],
    operationEvents: OperationEventEntity[],
    afterUpdate: (deliveries: PlanningsDeliveryEntity[]
  ) => void
  ) => {
    const changedShiftTruckIds = Array.from(new Set([
      ...operationEntities.filter((it) => it.isChanged).map((it) => ([it.shiftTruckId, it.originalShiftTruckId])).flat(),
      ...baseOperationEntities.filter((it) => it.isChanged).map((it) => it.shift_truck_id)
    ]));
    if (changedShiftTruckIds.length === 0) return;

    const changedOpertaionEntities = operationEntities.filter((it) => changedShiftTruckIds.includes(it.shiftTruckId))
      .sort((a, b) => {
        let ret = a.shiftTruckId - b.shiftTruckId;
        if (ret === 0) {
          ret = new Date(a.arrivalAt).getTime() - new Date(b.arrivalAt).getTime();
        }
        if (ret === 0) {
          ret = new Date(a.departureAt).getTime() - new Date(b.departureAt).getTime();
        }
        return ret;
      });
    const timelineEditDeliveries = changedOpertaionEntities.map((it, index) => {
        const event: OperationEventEntity = operationEvents.find((ev) => (
          ev.type === 'operationEvent' && ev.operations.find((op) => op.order.id === it.orderId && op.action === it.action)
        ));
        let workStartTime = event.start; // 画面に表示されている開始時間
        const workEndTime = event.end; // 画面に表示されている終了時間
        let waitingTime = 0;
        const waitingEvent: OperationEventEntity = operationEvents.find((ev) => (
          ev.type === 'operationWaitingEvent' && ev.operations.find((op) => op.order.id === it.orderId && op.action === it.action)
        ));
        if (waitingEvent) {
          workStartTime = waitingEvent.start;
          waitingTime = (new Date(waitingEvent.end).getTime() - new Date(waitingEvent.start).getTime()) / 1000;
        }
        if (index > 0) {
          const prev = changedOpertaionEntities[index - 1];
          if (prev.action === it.action && prev.latitude === it.latitude && prev.longitude === it.longitude) {
            // 同一地点の案件の場合は、2つ目移行は開始=終了時間とする
            workStartTime = workEndTime;
            waitingTime = 0;
          }
        }
        return {
          order_id: it.orderId,
          place_type: 'order',
          operation: it.action === '積' ? 'load' : 'unload',
          work_start_time: workStartTime,
          work_end_time: workEndTime,
          waiting_duration_seconds: waitingTime,
          shift_truck_id: it.shiftTruckId,
          name: it.action === '積' ? it.loadingName : it.unloadingName,
        };
      }).sort((a, b) => new Date(a.work_start_time).getTime() - new Date(b.work_start_time).getTime())
      .reduce((hash, it) => {
        hash[it.shift_truck_id] ||= [];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        hash[it.shift_truck_id].push(it);
        return hash;
      }, {});

    const timelineEditBaseOperations = baseOperationEntities
      .filter((it) => changedShiftTruckIds.includes(it.shift_truck_id))
      .map((it) => ({
        place_type: it.place_type,
        work_start_time: it.arrival_at,
        shift_truck_id: it.shift_truck_id,
      }))
      .reduce((hash, it) => {
        hash[it.shift_truck_id] ||= [];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        hash[it.shift_truck_id].push(it);
        return hash;
      }, {});

    const messages: string[] = [];
    Object.keys(timelineEditDeliveries).forEach((shiftTruckId) => {
      const baseOperations = timelineEditBaseOperations[shiftTruckId] as unknown as TimelineEditBaseOperationEntity[];
      const baseDeparture = baseOperations.find((it) => it.place_type === 'departure');
      const baseArrival = baseOperations.find((it) => it.place_type === 'arrival');
      const baseDepartureTime = new Date(baseDeparture.work_start_time).getTime();
      const baseArrivalTime = new Date(baseArrival.work_start_time).getTime();

      const deliveries = timelineEditDeliveries[shiftTruckId] as unknown as TimelineEditDeliveryEntity[];
      deliveries.forEach((delivery, index) => {
        if (index !== 0) {
          const prev = deliveries[index - 1];
          if (delivery.work_start_time < prev.work_end_time) {
            messages.push(`「${delivery.name} (${delivery.operation === 'load' ? '積' : '降'})」の開始時間は「${prev.name} (${prev.operation === 'load' ? '積' : '降'})」の終了時間より後にしてください。`);
          }
        }
        if (new Date(delivery.work_start_time).getTime() < baseDepartureTime) {
          messages.push(`「${delivery.name} (${delivery.operation === 'load' ? '積' : '降'})」の開始時間は車庫の出発時間より後にしてください。`);
        }
        if (baseArrivalTime < new Date(delivery.work_end_time).getTime()) {
          messages.push(`「${delivery.name} (${delivery.operation === 'load' ? '積' : '降'})」の開始時間は車庫の到着時間より前にしてください。`);
        }
      });
    });
    if (messages.length > 0) {
      messages.forEach((it) => { enqueueSnackbar(it); });
      return;
    }

    changedShiftTruckIds.forEach((shiftTruckId) => {
      if (shiftTruckId > 0 && !timelineEditDeliveries[shiftTruckId]) {
        // 案件がなくなった場合に空にしてリクエストしないと、一つの案件が複数人に割当たってしまう
        timelineEditDeliveries[shiftTruckId] = [];
      }
    });
    setIsLoading(true);

    manualPlanning.mutate(
      {
        startOn: startYMD,
        endOn: endYMD,
        timelineEditDeliveries,
        timelineEditBaseOperations,
      },
      {
        onSuccess: (data: AxiosResponse<ManualPlanningResponseEntity>) => {
          afterUpdate(data.data.deliveries);
          queryClient.setQueriesData(['useQueryPlanningsDeliveries', { startOn: startYMD, endOn: endYMD }], data.data.deliveries);

          const newDeliveries = data.data.deliveries.filter((delivery) => changedShiftTruckIds.includes(delivery.id));

          const newResources = resources.map((resource) => {
            const shiftTruckId = Number(resource.id);
            if (!changedShiftTruckIds.includes(shiftTruckId)) {
              return resource;
            }
            const delivery = newDeliveries.find((it) => it.id === shiftTruckId);
            const truck = truckData.find((it) => it.id === delivery.truckId);

            return {
              ...resource,
              summaries: calculateSummaries(delivery, truck)
            };
          });
          setResources(newResources);
        },
        onError: (e: AxiosError<{ message: string }>) => {
          enqueueSnackbar(fiveZeroZeroErrorMessage);
          resetQueries();
        },
        onSettled: () => {
          setIsLoading(false);
        }
      }
    );
  }, [calculateSummaries, endYMD, enqueueSnackbar, manualPlanning, queryClient, resetQueries, resources, startYMD, truckData]);

  const { operationSendMail } = useMutationPlanningOperations();

  const sendMail = useCallback(() => {
    const startOn = datetimeDecorator.toYyyyMmDd(startDate);
    const endOn = datetimeDecorator.toYyyyMmDd(endDate);
    const shiftTruckIds = deliveryData?.map((it: PlanningsDeliveryEntity) => it.id) ?? [];

    setIsLoading(true);
    operationSendMail.mutate({ startOn, endOn, shiftTruckIds }, {
      onSuccess: () => {
        enqueueSnackbar('ドライバーにメール送信しました。');
      },
      onError: () => { enqueueSnackbar('メール送信処理でエラーになりました'); },
      onSettled: () => {
        setIsLoading(false);
        resetQueries();
      }
    });
  }, [deliveryData, endDate, enqueueSnackbar, operationSendMail, resetQueries, startDate]);

  const { addOrder, deleteOrders, restoreSplittedOrder } = useMutationOrder();

  const mutateRestoreSplittedOrder: (orderId: number) => void = useCallback((orderId) => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('分割した案件を復元して未割り当てにします。よろしいですか？')) return;

    setIsLoading(true);
    restoreSplittedOrder.mutate(
      orderId,
      {
        onSuccess: () => {
          resetQueries();
          enqueueSnackbar('分割した案件をもとに戻しました。');
        },
        onError: () => {
          enqueueSnackbar('分割した案件を元に戻す処理でエラーが発生しました。');
        },
        onSettled: () => {
          setIsLoading(false);
        }
      }
    );
  }, []);

  const mutateDeleteSpecificOrder: (orderId: number) => void = useCallback((orderId) => {
    setIsLoading(true);

    deleteOrders.mutate([orderId]);

    resetQueries();

    setIsLoading(false);
  }, [deleteOrders, resetQueries]);
  const [directionSettingDialogIsOpen, setDirectionSettingDialogIsOpen] = useState<boolean>(false);
  const updateDirectionSettingDialogIsOpen = useCallback((val: boolean) => {
    setDirectionSettingDialogIsOpen(val);
  }, []);

  const [selectPrintDialogIsOpen, setSelectPrintDialogIsOpen] = useState<boolean>(false);
  const selectPrintButtonOnClick = useCallback(() => {
    setSelectPrintDialogIsOpen(true);
  }, []);

  const selectPrintDialogClose = () => {
    setSelectPrintDialogIsOpen(false);
  };

  const printAllTruckDirectionsOnClick = useCallback(() => {
    const ids = deliveryData !== null ? deliveryData.map((it) => it.shiftId) : [];
    const asidePath = `/truck-directions/?ids=${ids.join(',')}`;

    window.open(asidePath);
  }, [deliveryData]);

  const printUnloadTruckDirectionsOnClick = useCallback(() => {
    const ids = deliveryData !== null ? deliveryData.map((it) => it.shiftId) : [];
    const asidePath = `/truck-directions/?ids=${ids.join(',')}&action=降`;

    window.open(asidePath);
  }, [deliveryData]);

  const printOperationDirectionUrl = useCallback(() => {
    if (!deliveryData) return '';

    const ids = deliveryData !== null ? deliveryData.map((it) => it.shiftId) : [];
    const asidePath = `/operation-directions/?ids=${ids.join(',')}`;

    return asidePath;
  }, [deliveryData]);

  const printPickingListOnClick = useCallback(() => {
    const ids = deliveryData !== null ? deliveryData.map((it) => it.shiftId) : [];
    const asidePath = `/picking-list/?ids=${ids.join(',')}`;

    window.open(asidePath);
  }, [deliveryData]);

  const downloadPlanCsvOnClick = useCallback(() => {
    window.open(`/api/v2/shifts/csv?start_at_range_start_on=${startYMD}&start_at_range_end_on=${endYMD}`);
  }, [startYMD, endYMD]);

  const addSelectedOrderId = useCallback(
    (id: number) => {
      dispatchSelectedOrderIds({
        type: 'add',
        payload: id
      });
    },
    [],
  );
  const removeSelectedOrderId = useCallback(
    (id: number) => {
      dispatchSelectedOrderIds({
        type: 'remove',
        payload: id
      });
    },
    [],
  );

  const mutateDeleteOrder: () => void = useCallback(() => {
    if (!selectedOrderIds) return;
    if (!selectedOrderIds.length) return;

    enqueueSnackbar(`${selectedOrderIds.length.toLocaleString()}件の案件を削除します`);
    setIsLoading(true);

    deleteOrders.mutate(
      selectedOrderIds,
      {
        onSuccess: () => {
          dispatchSelectedOrderIds({
            type: 'bulkRemove',
            payload: selectedOrderIds
          });

          enqueueSnackbar(`${selectedOrderIds.length.toLocaleString()}件の案件を削除しました`);
          resetQueries();
        },
        onError: () => {
          enqueueSnackbar('案件の削除でエラーが発生しました。');
        },
        onSettled: () => {
          setIsLoading(false);
        }
      }
    );
  }, [deleteOrders, enqueueSnackbar, resetQueries, selectedOrderIds]);

  const mutateCloneOrder: (order: OrderEntity) => void = useCallback((order) => {
    setIsLoading(true);

    order.id = null;
    order.uuid = null;
    // eslint-disable-next-line no-void
    void addOrder.mutateAsync(order).finally(() => {
      // src/components/V2OrderForm/index.tsx の reset を真似ている
      const resetQueryKeys = ['shifts', 'shift', 'unallocatedOrders', 'order', 'shippers', 'places', 'useQueryPlanningOrderStatistics'];
      // eslint-disable-next-line no-void
      void queryClient.resetQueries({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        predicate: (query) => resetQueryKeys.includes(`${query.queryKey[0]}`),
      });
      resetQueries();
      setIsLoading(false);
    });
  }, [addOrder, queryClient, resetQueries]);

  useMemo(() => {
    dispatchDriverEntities({
      type: 'set',
      payload: driverData || []
    });
  }, [driverData]);

  useMemo(() => {
    dispatchDeliveryEntities({
      type: 'set',
      payload: deliveryData || []
    });
  }, [deliveryData]);

  useMemo(() => {
    if (!orderData) return;

    const map = new Map<number, OrderEntity>();
    orderData.pages.flatMap((it) => it.data).forEach((it) => {
      map.set(it.id, it);
    });
    dispatchOrderEntityMap({
      type: 'set',
      payload: map
    });
  }, [orderData]);

  if ([trucksIsLoading, driversIsLoading, ordersIsLoading].some((bool) => bool)) return <LoadingComponent />;

  return (
    <Presenter
      startDate={startDate}
      endDate={endDate}
      garageData={garageData}
      deliveryEvents={deliveryEvents}
      resources={resources}
      orderData={orderEntities}
      reset={reset}
      save={save}
      truckData={truckData}
      deliveryData={deliveryData}
      mutateDeleteOrdersOperations={mutateDeleteOrdersOperations}
      isLoading={isLoading}
      groupEntities={groupEntities}
      selectedGroupEntity={selectedGroupEntity}
      updateSelectedGroupEntity={updateSelectedGroupEntity}
      sendMail={sendMail}
      mutateDeleteSpecificOrder={mutateDeleteSpecificOrder}
      printOperationDirectionUrl={printOperationDirectionUrl}
      printAllTruckDirectionsOnClick={printAllTruckDirectionsOnClick}
      printUnloadTruckDirectionsOnClick={printUnloadTruckDirectionsOnClick}
      printPickingListOnClick={printPickingListOnClick}
      downloadPlanCsvOnClick={downloadPlanCsvOnClick}
      selectPrintDialogIsOpen={selectPrintDialogIsOpen}
      selectPrintButtonOnClick={selectPrintButtonOnClick}
      selectPrintDialogClose={selectPrintDialogClose}
      selectedOrderIds={selectedOrderIds}
      addSelectedOrderId={addSelectedOrderId}
      removeSelectedOrderId={removeSelectedOrderId}
      unit={unit}
      mutateDeleteOrder={mutateDeleteOrder}
      mutateCloneOrder={mutateCloneOrder}
      planningOrderStatisticsEntity={planningOrderStatisticsEntity}
      customInputFields={customInputFields}
      driverEntities={driverEntities}
      deliveryEntities={deliveryEntities}
      orderEntityMap={orderEntityMap}
      directionSettingDialogIsOpen={directionSettingDialogIsOpen}
      updateDirectionSettingDialogIsOpen={updateDirectionSettingDialogIsOpen}
      orderSearchKw={orderSearchKw}
      setOrderSearchKw={setOrderSearchKw}
      currentlyOrderSearching={currentlyOrderSearching}
      setCurrentlyOrderSearching={setCurrentlyOrderSearching}
      mutateRestoreSplittedOrder={mutateRestoreSplittedOrder}
    />
  );
});

export default Component;
